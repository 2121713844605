
















































import { Component, Vue } from "vue-property-decorator";
import AppArchiveItemWidget from "@/components/widgets/AppArchiveItemWidget.vue";
import AppBadge from "@/components/core/AppBadge.vue";
import AppCreateUnitWidget from "@/components/widgets/AppCreateUnitWidget.vue";
import AppDisableItemWidget from "@/components/widgets/AppDisableItemWidget.vue";
import AppUnitList from "@/components/AppUnitList.vue";
import AppUpdateItemModal from "@/components/modals/AppUpdateItemModal.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findItem } from "@/utils/api";
import { hasMarketerPermissions } from "@/utils/authorization";
import { NotFoundException, notFoundHandler } from "@/utils/exception";

@Component({
  components: {
    AppArchiveItemWidget,
    AppBadge,
    AppCreateUnitWidget,
    AppDisableItemWidget,
    AppUnitList,
    AppUpdateItemModal,
    TheHeader,
    TheMain,
  },
})
export default class Item extends Vue {
  get catalogId(): pro.Id {
    return Number(this.$route.params.catalogId);
  }

  get itemId(): pro.Id {
    return Number(this.$route.params.itemId);
  }

  isMarketer = false;
  item: pro.ItemDetail | null = null;
  showUpdateItemModal = false;

  async mounted() {
    const catalog = { id: this.catalogId };
    this.isMarketer = await hasMarketerPermissions(catalog);
    await this.find();
  }

  /**
   * Finds an Item
   */
  async find() {
    const item = { id: this.itemId };
    try {
      this.item = await findItem(item, this.catalogId);
    } catch (err) {
      if (err instanceof NotFoundException) notFoundHandler();
    }
  }
}
