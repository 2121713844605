








































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppSelect from "@/components/core/AppSelect.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { createUnit } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";
import { UOMS } from "@/main";

@Component({
  components: {
    AppButton,
    AppLabel,
    AppSelect,
    AppWidget,
  },
})
export default class AppCreateUnitWidget extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  @Prop({ required: true })
  readonly item!: pro.ItemDetail;

  notification: Notification | null = null;
  saving = false;
  unit: pro.Unit = { uom: "" };

  get supportedUoms() {
    return UOMS;
  }

  get existingUoms(): pro.UoM[] {
    if (!this.item.units) return [];
    return this.item.units.map(unit => unit.uom) as pro.UoM[];
  }

  availableUoms() {
    const excludedUoms = this.existingUoms ?? [];
    const supportedUoms = Object.values(this.supportedUoms);
    return supportedUoms.filter(uom => !excludedUoms.includes(uom));
  }

  /**
   * Creates a new Unit
   */
  async createUnit(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      let unit: pro.Unit = { ...this.unit, item: { id: this.item.id } };
      unit = await createUnit(unit, this.catalogId);
      if (this.item && this.item.units) this.item.units.push(unit);
      this.unit.uom = "";
    } catch (err) {
      this.notification = createErrorNotification(
        "The unit could not be created",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
