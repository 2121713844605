

























/**
 * The switch's background is rendered as a label so that it triggers the input
 * value change when clicked.
 */
import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppSwitch extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Model("change", { type: Boolean })
  readonly checked!: boolean;
}
