


















import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { updateItem } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppWidget,
  },
})
export default class AppArchiveItemWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: pro.ItemDetail;

  @Prop({ type: [String, Number], required: true })
  readonly catalogId!: pro.Id;

  get seriesId(): pro.Id {
    return this.item.series?.id ?? 0;
  }

  notification: Notification | null = null;
  saving = false;

  async archiveItem(): Promise<void> {
    this.saving = false;

    try {
      const item = {
        id: this.item.id,
        archived: true,
      };
      await updateItem(item, this.catalogId);
      this.redirect();
    } catch (err) {
      this.notification = createErrorNotification(
        "The requested changes could not be made",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  redirect(): void {
    const catalogId = String(this.catalogId);
    const seriesId = String(this.seriesId);
    this.$router.push({
      name: "SERIES",
      params: { catalogId, seriesId },
    });
  }
}
