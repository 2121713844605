



























import { Component, Prop, Vue } from "vue-property-decorator";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppUnit from "@/components/AppUnit.vue";
import { updateItem, updateUnit } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppListGroup,
    AppListGroupItem,
    AppUnit,
  },
})
export default class AppUnitList extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  @Prop({ type: Object, required: true })
  readonly item!: pro.ItemDetail;

  defaultUnit: pro.Unit | null = null;
  notification: Notification | null = null;
  saving = false;

  mounted() {
    if (this.item.defaultUnit) this.defaultUnit = this.item.defaultUnit;
  }

  /**
   * Saves changes to a Unit's backorder status
   */
  async toggleBackordered(unit: pro.Unit): Promise<void> {
    console.log("backordered");
    try {
      await updateUnit(
        { id: unit.id, backordered: unit.backordered },
        this.catalogId
      );
    } catch (err) {
      const action = unit.backordered ? "backordered" : "replenished";
      this.notification = createErrorNotification(
        `The unit could not be ${action}`,
        err.message
      );
      unit.backordered = !unit.backordered;
    }
  }

  /**
   * Saves changes to a Unit's backorder status
   */
  async toggleDisabled(unit: pro.Unit): Promise<void> {
    try {
      await updateUnit(
        { id: unit.id, disabled: unit.disabled },
        this.catalogId
      );
    } catch (err) {
      const action = unit.disabled ? "disabled" : "enabled";
      this.notification = createErrorNotification(
        `The unit could not be ${action}`,
        err.message
      );
      unit.disabled = !unit.disabled;
    }
  }

  /**
   * Sets an Item's default Unit
   */
  async setDefaultUom(unit: pro.Unit): Promise<void> {
    this.notification = null;
    this.saving = false;
    const currentDefault = { ...this.defaultUnit } ?? null;
    this.defaultUnit = { id: unit.id };

    try {
      const item = {
        id: this.item.id,
        defaultUnit: this.defaultUnit,
      };
      await updateItem(item, this.catalogId);
    } catch (err) {
      this.notification = createErrorNotification(
        "The default unit could not be changed",
        err.message
      );
      this.defaultUnit = currentDefault;
    } finally {
      this.saving = false;
    }
  }
}
