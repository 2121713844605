

















import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { updateItem } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppWidget,
  },
})
export default class AppDisableItemWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: pro.ItemDetail;

  @Prop({ type: [String, Number], required: true })
  readonly catalogId!: pro.Id;

  notification: Notification | null = null;
  saving = false;

  currentState() {
    return this.item.disabled ? "Enable" : "Disable";
  }

  /**
   * Disables an Item
   */
  async disableItem(disabled: boolean): Promise<void> {
    this.saving = true;

    try {
      const item = {
        id: this.item.id,
        disabled,
      };
      await updateItem(item, this.catalogId);
      this.item.disabled = disabled;
    } catch (err) {
      this.notification = createErrorNotification(
        "The requested changes could not be made",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
