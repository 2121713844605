





































import { Component, Prop, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppSwitch from "@/components/core/AppSwitch.vue";

@Component({
  components: {
    AppBadge,
    AppButton,
    AppSwitch,
  },
})
export default class AppUnit extends Vue {
  @Prop({ type: Object, required: true })
  readonly unit!: pro.Unit;

  @Prop({ type: Boolean, default: false })
  readonly isDefaultUnit!: boolean;
}
