
























































import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";
import { updateItem } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppCountdown,
    AppLabel,
    AppModal,
  },
})
export default class AppUpdateItemModal extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  @Prop({ type: Object, required: true })
  existingItem!: pro.Item;

  notification: Notification | null = null;
  saving = false;
  updatedItem: pro.Item | null = null;

  mounted() {
    this.updatedItem = {
      id: this.existingItem.id,
      sku: this.existingItem.sku,
      description: this.existingItem.description,
    };
  }

  async updateItem(): Promise<void> {
    this.saving = true;

    try {
      if (!this.updatedItem) throw new Error("Missing required fields.");
      await updateItem(this.updatedItem, this.catalogId);
      this.showChanges(this.updatedItem);
      this.$emit("close");
    } catch (err) {
      this.notification = createErrorNotification(
        "The requested changes could not be made",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  showChanges(item: pro.Item): void {
    this.existingItem.sku = item.sku;
    this.existingItem.description = item.description;
  }
}
