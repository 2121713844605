import Fetch, { FetchRequestOptions } from "./fetch";
import { currentUser, isSuperUser } from "@/utils/authentication";
import store from "@/utils/store";
import { NotFoundException } from "./exception";

export const CATALOG_ROLES = {
  Admin: "ADMIN",
  Approver: "APPROVER",
  Marketer: "MARKETER",
  Requestor: "REQUESTOR",
  Shipper: "SHIPPER",
};

const roleStore = (catalogId: pro.Id): string => `pro_catalog_${catalogId}`;

/**
 * Manages backend requests
 */
async function requestHandler(
  req: keyof Fetch,
  path: string,
  options?: FetchRequestOptions
): Promise<unknown> {
  const origin = process.env.VUE_APP_API_ORIGIN;
  const fetch = new Fetch({ baseURL: origin });
  const res = await fetch[req](path, {
    ...options,
    withCredentials: true,
  });
  return res.data;
}

/**
 * Stores the Current User's Catalog Roles from the API
 */
async function saveCatalogRoles(
  catalog: pro.Catalog
): Promise<pro.CatalogRole[]> {
  const user = currentUser();
  const res = (await requestHandler(
    "get",
    `/catalogs/${catalog.id}/members/${user.id}`
  )) as pro.Member;
  store.set(roleStore(catalog.id ?? ""), res.roles ?? []);
  return res.roles ?? [];
}

/**
 * Fetches the Current User's roles in a Catalog
 */
async function fetchCatalogRoles(
  catalog: pro.Catalog,
  sync = false
): Promise<pro.CatalogRole[]> {
  let roles = store.get(roleStore(catalog.id ?? "")) as
    | pro.CatalogRole[]
    | undefined;
  if (sync || !roles) roles = await saveCatalogRoles(catalog);
  return roles;
}

/**
 * Checks whether the Current User has a role in a Catalog
 */
async function hasCatalogRole(
  catalog: pro.Catalog,
  role: pro.CatalogRole
): Promise<boolean> {
  let roles: pro.CatalogRole[] = [];
  try {
    roles = await fetchCatalogRoles(catalog);
  } catch (err) {
    if (err instanceof NotFoundException) return false;
  }
  return roles.includes(role);
}

/**
 * Checks whether the Current User is an Admin
 */
async function isAdmin(catalog: pro.Catalog): Promise<boolean> {
  return hasCatalogRole(catalog, CATALOG_ROLES.Admin);
}

/**
 * Checks whether the Current User is a Shipper
 */
async function isShipper(catalog: pro.Catalog): Promise<boolean> {
  return hasCatalogRole(catalog, CATALOG_ROLES.Shipper);
}

/**
 * Checks whether the Current User is a Requestor
 */
async function isRequestor(catalog: pro.Catalog): Promise<boolean> {
  return hasCatalogRole(catalog, CATALOG_ROLES.Requestor);
}

/**
 * Checks whether the Current User is a Marketer
 */
async function isMarketer(catalog: pro.Catalog): Promise<boolean> {
  return hasCatalogRole(catalog, CATALOG_ROLES.Marketer);
}

/**
 * Checks whether the Current User is an Approver
 */
async function isApprover(catalog: pro.Catalog): Promise<boolean> {
  return hasCatalogRole(catalog, CATALOG_ROLES.Approver);
}

export async function hasAdminPermissions(
  catalog: pro.Catalog
): Promise<boolean> {
  if (isSuperUser()) return true;
  return isAdmin(catalog);
}

export async function hasShipperPermissions(
  catalog: pro.Catalog
): Promise<boolean> {
  if (isSuperUser()) return true;
  return isShipper(catalog);
}

export async function hasRequestorPermissions(
  catalog: pro.Catalog
): Promise<boolean> {
  if (isSuperUser()) return true;
  return isRequestor(catalog);
}

export async function hasMarketerPermissions(
  catalog: pro.Catalog
): Promise<boolean> {
  if (isSuperUser()) return true;
  return isMarketer(catalog);
}

export async function hasApproverPermissions(
  catalog: pro.Catalog
): Promise<boolean> {
  if (isSuperUser()) return true;
  return isApprover(catalog);
}
